import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import '../assets/sass/new-age.scss';

const SEO = ({ title, description, image, slug, isBlog }) => (
  <StaticQuery
    query={detailsQuery}
    render={data => {
      const metaTitle = title || data.site.siteMetadata.title;
      const metaDescription = description || data.site.siteMetadata.description;
      const metaSiteUrl = data.site.siteMetadata.siteUrl;
      const metaImage =
        (image && `${metaSiteUrl}${image}`) || data.site.siteMetadata.logo;
      const metaAuthor = data.site.siteMetadata.author;
      const metaSlug = slug || '';

      return (
        <Helmet
          htmlAttributes={{
            lang: 'en',
          }}
          title={metaTitle}
          titleTemplate={
            metaTitle === 'Home'
              ? data.site.siteMetadata.title
              : isBlog
              ? `%s | Blog`
              : `%s | ${data.site.siteMetadata.title}`
          }
          meta={[
            {
              name: 'description',
              content: { metaDescription },
            },
            {
              name: 'keywords',
              content:
                'gust, influencers, app, influencer, Sda media, site, web',
            },
            {
              property: 'image',
              content: metaImage,
            },
            { property: 'og:locale', content: 'en_US' },
            { property: 'og:site_name', content: metaTitle },
            { property: 'og:title', content: metaTitle },
            { property: 'og:url', content: `${metaSiteUrl}${metaSlug}` },
            {
              property: 'og:description',
              content: metaDescription,
            },
            {
              property: 'og:image',
              content: metaImage,
            },
            {
              property: 'og:image:width',
              content: '600',
            },
            {
              property: 'og:image:height',
              content: '315',
            },
            { name: 'twitter:card', content: 'summary_large_image' },
            {
              name: 'twitter:image',
              content: metaImage,
            },
            { name: 'twitter:site', content: metaSiteUrl },
            { name: 'twitter:creator', content: metaAuthor },
            { name: 'twitter:title', content: metaTitle },
            {
              name: `twitter:description`,
              content: metaDescription,
            },
          ]}
        />
      );
    }}
  />
);

SEO.defaultProps = {
  lang: 'en',
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        logo
        siteUrl
        author
      }
    }
  }
`;
